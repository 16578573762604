import React from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';
import './styles/notfound.scss';
import imgfLogo from './img/img-acreditese.svg';
import imgfNotFound from './img/img-notfound.png';

export default function NotFound() {
  return (
    <section id="page-404">
      <div id="page-404-logo">
        <a href="/">
          <img src={imgfLogo}/>
        </a>
      </div>
      <div id="page-404-image">
        <img alt={intl.get("pagina_nao_encontrada")} src={imgfNotFound} />
      </div>
      <div id="page-404-text">
        <h1>Ooops!</h1>
        <h2>{intl.get('NotFound.page_not_located_1')}<br/>{intl.get('NotFound.page_not_located_2')}</h2>
        <p>{intl.get('NotFound.return_to_index')}</p>
      </div>
      <div id="page-404-button">
        <Button className="btn back" onClick={() => {
          window.location.href = "/";
        }}>
          {intl.get('NotFound.index')}
        </Button>
      </div>
    </section>
  );
}
