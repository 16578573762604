// atenção: na próxima vez que precisarmos usar HTML perigoso, lembremos de sanitizá-lo para aplicar a sanitização nesses dangerouslySetInnerHTML também
import React, { Component } from 'react';
import axios from 'axios';
import intl from 'react-intl-universal';
import { Button, Input } from 'reactstrap';
import { ReCaptcha } from 'react-recaptcha-google';
import _ from 'lodash';
import imgfLogo from '../../assets/img/logo.png';

class PreRegister extends Component {
  constructor(oProps) {
    super(oProps);
    document.body.classList.add('w-bkg');

    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);

    this.state = {
      cToken: undefined,
      cEmail: oProps.match.params.cEmail ? oProps.match.params.cEmail : '',
      oHasAlert: {
        bShow: false,
        cType: 'error',
        cMessage: ''
      },
      bIsSend: false
    };
  }

  componentDidMount = () => {
    const { cEmail } = this.state;

    if (cEmail) {
      this.makeResend();
    }
  };

  onLoadRecaptcha = () => {
    if (this.captcha) {
      this.captcha.reset();
    }
  };

  verifyCallback = cRecaptchaToken => {
    this.setState({
      cToken: cRecaptchaToken,
      oHasAlert: {
        bShow: false,
        cType: '',
        cMessage: ''
      }
    });
  };

  closeAlert = () => {
    this.setState({
      oHasAlert: {
        bShow: false,
        cType: '',
        cMessage: ''
      }
    });
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  makeResend = evt => {
    if (evt) evt.preventDefault();
    this.setState({
      bResendLink: true,
      oHasAlert: {
        bShow: false,
        cType: 'error',
        cMessage: ''
      }
    });
  };

  resendLink = evt => {
    evt.preventDefault();
    const { cEmail } = this.state;
    this.setState({
      bIsSend: true
    });
    axios
      .post(`/customer/resend-activation`, {
        cEmail
      })
      .then(() => {
        this.setState({
          bIsSend: false,
          oHasAlert: {
            bShow: true,
            cType: 'success',
            cMessage: [intl.get('RegisterActivation.sucesso_email_reativacao')]
          }
        });
      })
      .catch(() => {
        this.setState({
          bIsSend: false,
          oHasAlert: {
            bShow: true,
            cType: 'error',
            cMessage: [intl.get('RegisterActivation.erro_email_reativacao')]
          }
        });
      });
  };

  handleSubmit = evt => {
    const { cToken, cEmail } = this.state;

    evt.preventDefault();

    if (process.env.REACT_APP_USE_RECAPTCHA === 'true' && cToken === undefined) {
      this.setState({
        oHasAlert: {
          bShow: true,
          cType: 'error',
          cMessage: intl.get('captcha_obrigatorio')
        }
      });
    } else {
      this.setState({
        bIsSend: true
      });

      axios
        .post(`/customer/pre-register`, {
          cEmail
        })
        .then(oResponse => {
          this.setState({
            bIsSend: false,
            bResendLink: true,
            oHasAlert: {
              bShow: true,
              cType: 'success',
              cMessage: oResponse.data.cMsg
            }
          });
        })
        .catch(oError => {
          const oMessage =
            typeof oError.response.data === 'string'
              ? oError.response.data
              : _.toArray(oError.response.data).map(oMsg => oMsg);

          this.setState({
            bIsSend: false,
            oHasAlert: {
              bShow: true,
              cType: 'error',
              cMessage: oMessage
            }
          });
        });
    }
  };

  loadRecaptcha = () => {
    if (process.env.REACT_APP_USE_RECAPTCHA === 'true') {
      return (
        <ReCaptcha
          ref={elCaptcha => {
            this.captcha = elCaptcha;
          }}
          hl="pt-BR"
          size="normal"
          render="explicit"
          sitekey="6LfSki0UAAAAAPvDudZ_vVQVkK5f6WHFqnLSUHbu"
          onLoad={this.onLoadRecaptcha}
          onSuccess={this.verifyCallback}
        />
      );
    }
  };

  render() {
    const { oHasAlert, bIsSend, bResendLink, cEmail } = this.state;
    return (
      <section id="register">
        <div className="wrapper-login">
          <header>
            <div className="logo">
              <img src={imgfLogo} />
            </div>
            <h1>{intl.get('Login.cadastro')}</h1>
          </header>
          <main className="form">
            <form onSubmit={this.handleSubmit} className="form-horizontal">
              <Input
                maxLength="250"
                className="form-control"
                onChange={this.handleChange}
                placeholder={intl.get('cemail')}
                value={cEmail}
                id="cEmail"
                required
                autoFocus=""
                name="cEmail"
                type="email"
              />

              {!bResendLink ? (
                <>
                  {this.loadRecaptcha()}
                  <br />
                  <p className="sc-login">
                    {intl.get('Login.sou_cadastrado')}
                    <a href="/login">{intl.get('Login.entrar')}</a>
                  </p>

                  <Button className={bIsSend ? 'loading disabled' : ''} type="submit">
                    {intl.get('Login.cadastrar')}
                  </Button>
                </>
              ) : (
                <Button
                  onClick={evt => this.resendLink(evt)}
                  className={bIsSend ? 'loading disabled' : ''}
                  type="button"
                >
                  {intl.get('Login.reenviar_link')}
                </Button>
              )}

              {oHasAlert.bShow && (
                <div className={`alert ${oHasAlert.cType}`}>
                  {oHasAlert.cMessage === 'Reenviar link' ? (
                    <>
                      {intl.get('Login.cliente_pre_cadastrado_1')}
                      <a onClick={evt => this.makeResend(evt)} href="#!">
                        {intl.get('Login.cliente_pre_cadastrado_2')}
                      </a>
                    </>
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: oHasAlert.cMessage }} />
                  )}

                  <span
                    aria-labelledby={`alert ${oHasAlert.cType}`}
                    title="Fechar"
                    onClick={this.closeAlert}
                    onKeyPress={this.closeAlert}
                    className="close"
                    role="button"
                    tabIndex={0}
                  />
                </div>
              )}
            </form>
          </main>
        </div>
      </section>
    );
  }
}

export default PreRegister;
