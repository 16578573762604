/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import intl from 'react-intl-universal';
import imgfLogo from '../../assets/img/logo-acreditese.png';
import imgfNoPic from '../../assets/img/nopic.svg';
import imgfLoading from '../../assets/img/preloader-black.gif';
import OutsideHandlerClick from '../../components/OutsideHandlerClick';
import Axios from '../../config/Axios';
import url from '../../config/Url';
import { signOut, signIn } from '../../config/Authentication';
import Nav from './Nav';
import NavMain from './NavMain';
import { canAtLeast } from '../../config/Permissions';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

export function doLogout() {
  signOut();
  Axios.get('/logout')
    .then(() => {
      window.location.reload();
    })
    .catch(() => {
      window.location.reload();
    });
}

const respondToVisibility = (elElement, fnCallback) => {
  const oOptions = {
    root: document.documentElement
  };

  const inobObserver = new IntersectionObserver(aEntries => {
    aEntries.forEach(oEntry => {
      fnCallback(oEntry.intersectionRatio > 0);
    });
  }, oOptions);

  inobObserver.observe(elElement);
};

class Header extends Component {
  tmInterval = null;

  constructor(oProps) {
    super(oProps);
    const oLocalStorage = new EncodedLocalStorage();

    this.state = {
      bMobileMenuVisible: false,
      oUser: [],
      bLoading: true,
      bStatusUpdated: false,
      nUserId: oLocalStorage.get('nUserId')
    };
  }

  componentDidMount = () => {
    this.fetchImage();
    this.getNewMessages(false);
    this.getStatus();

    this.tmInterval = setInterval(() => {
      this.getNewMessages(true);
    }, 60000);

    respondToVisibility(document.getElementById('menu-button'), bVisible => {
      let elMenu = document.getElementById('menu-button');
      if (elMenu != null && elMenu.classList.contains('active')) {
        elMenu.classList.toggle('active');
      }
      this.setState({ bMobileMenuVisible: bVisible });
    });
  };

  componentWillReceiveProps = () => {
    this.fetchImage();
    this.getStatus();
  };

  componentWillUnmount() {
    clearInterval(this.tmInterval);
  }

  fetchImage = () => {
    const { nUserId } = this.state;

    Axios.get(`/user/${nUserId}`)
      .then(oResponse => {
        this.setState({
          oUser: oResponse.data,
          bLoading: false
        });
      })
      .catch(oError => { });
  };

  getStatus = () => {
    Axios.get(`/status/login`)
      .then(oResponse => {
        signIn(oResponse.data);
        this.setState({
          bStatusUpdated: true
        });
      })
      .catch(oError => { });
  };

  openMenu = () => {
    document.getElementById('main-menu').classList.toggle('opened');
    document.getElementById('menu-button').classList.toggle('active');
  };

  toggleUsermenu = cDisplay => {
    document.getElementById('user-menu').style.display = cDisplay;
  };

  getNewMessages = bIsAutomatic => {
    if (bIsAutomatic) {
      Axios.get('/notification/queryNotification', {
        params: {
          bNotRenewing: false
        }
      })
        .then(oResponse => {
          this.setState({
            bHasMessage: oResponse.data
          });
        })
        .catch(oError => { });
    } else {
      Axios.get('/notification/queryNotification')
        .then(oResponse => {
          this.setState({
            bHasMessage: oResponse.data
          });
        })
        .catch(oError => { });
    }
  };

  render() {
    const { bLoading, oUser, bHasMessage, bStatusUpdated, bMobileMenuVisible } = this.state;
    const cVersion = process.env.REACT_APP_VERSION;
    const cStatus = process.env.REACT_APP_STATUS;
    const oLocalStorage = new EncodedLocalStorage();

    return (
      <>
        <header id="header">
          <NavLink to="/" id="logo">
            <img src={imgfLogo} className="App-logo" />
            {cStatus == 'show' ? <span id="version">{cVersion}</span> : ''}
          </NavLink>

          <NavMain />

          <span onKeyPress={this.openMenu} onClick={this.openMenu} id="menu-button" tabIndex="0" role="button">
            <span />
          </span>

          <span
            onKeyPress={() => this.toggleUsermenu('block')}
            onClick={() => this.toggleUsermenu('block')}
            id="user-tip"
            className="user-tip"
            tabIndex="0"
            role="button"
          >
            {bLoading ? (
              <img alt={intl.get('carregando')} src={imgfLoading} />
            ) : (
              <img
                src={
                  oUser.user_cavatar
                    ? oUser.user_cavatar
                    : imgfNoPic
                }
                alt={intl.get('usuario')}
              />
            )}
            {/* <p>{oUser.customer.cust_cfantasyname}</p> */}
            <p>{oUser.customer ? oUser.customer.cust_cfantasyname : ''}</p>
          </span>

          {canAtLeast(['edit-customer', 'access-plan', 'admin']) && (
            <NavLink to="/settings/client/update/" title="Configurações" className="config-tip" id="config-tip" />
          )}

          <NavLink to="/notification" id="notifications-tip">
            {bHasMessage && <span />}
          </NavLink>
        </header>

        <OutsideHandlerClick handleClickOutside={() => this.toggleUsermenu('none')}>
          <nav className="animated fadeIn" style={{ display: 'none' }} id="user-menu">
            <div id="user-card">
              <div id="user-avatar">
                <img
                  src={
                    oUser.user_cavatar
                      ? oUser.user_cavatar
                      : imgfNoPic
                  }
                  alt={intl.get('usuario')}
                />
              </div>
              <div id="user-info">
                <h2>{oUser ? oUser.user_cname : ''}</h2>
                <p className="job-title">{oUser ? oUser.user_crole : ''}</p>
              </div>
            </div>

            <ul>
              <li>
                <NavLink to="/my-account/general" title={intl.get('minha_conta')}>
                  {intl.get('minha_conta')}
                </NavLink>
              </li>

              {oLocalStorage.get('bIsConsultant') == 'true' && (
                <li>
                  <NavLink to="/quality-panel-consultant" title={intl.get('minha_conta')}>
                    {intl.get('minhas_empresas')}
                  </NavLink>
                </li>
              )}

              <li>
                <a href="/#" title={intl.get('sair')} onClick={() => doLogout()}>
                  {intl.get('sair')}
                </a>
              </li>
            </ul>
          </nav>
        </OutsideHandlerClick>

        {bStatusUpdated && bMobileMenuVisible && <Nav />}
      </>
    );
  }
}

export default Header;
