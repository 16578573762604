import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import axios from 'axios';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import url from './config/Url';
import Site from './pages/site/Site';
import Index from './pages/default/Index';
import ScriptLoader from './components/ScriptLoader';

axios.defaults.baseURL = `${url.cBase}api`;
axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('cAccessToken')}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';
const cSubdomain = window.location.hostname.split('.')[0];

ReactDOM.render(
  <BrowserRouter>
    <div> {/* Envolve todos os componentes em um único elemento <div> */}
      {cSubdomain === 'site' ? <Site /> : <App />}
      <ScriptLoader /> {/* Adicione o componente ScriptLoader aqui */}
    </div>
  </BrowserRouter>,
  document.getElementById('root')
);

if (document.addEventListener) {
  document.addEventListener(
    'invalid',
    evtListened => {
      evtListened.target.className += ' invalid';
    },
    true
  );
}

// const elWBotScript = document.createElement('script');
// elWBotScript.src = 'https://wbot.chat/index.js';
// elWBotScript.async = true;
// elWBotScript.setAttribute('token', '29397b7b55a12e7965205d430b79ad1f');
// document.body.appendChild(elWBotScript);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
