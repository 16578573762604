import React, { Component } from 'react';
import validator from 'validator';
import { Button } from 'reactstrap';
import axios from 'axios';
import intl from 'react-intl-universal';
import imgfLogo from '../../assets/img/logo.png';
import { InputPassword } from '../../components/InputPassword';
import { signIn } from '../../config/Authentication';
import './styles/registeractivation.scss';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

class RegisterActivation extends Component {
  constructor(oProps) {
    super(oProps);

    document.body.classList.add('w-bkg');

    this.state = {
      cToken: oProps.match.params.cToken,
      user_cpassword: '',
      user_cpasswordconfirm: '',
      bHasAlert: false,
      aMsgErrors: [''],
      cAlertType: 'error',
      bIsSending: false,
      bValidPassword: false,
      bIsSameUser: false
    };
  }

  componentDidMount() {
    const { cToken } = this.state;

    axios.get(`/register/validate-token/${cToken}`).catch(oError => {
      if (oError.response.status === 403) {
        this.setState({
          bHasAlert: true,
          aMsgErrors: [oError.response.data.errors],
          cAlertType: 'error',
          bIsSameUser: true
        });
      } else {
        this.setState({
          bHasAlert: true,
          aMsgErrors: [oError.response.data.errors],
          cAlertType: 'error',
          bIsSending: false
        });
      }
    });
  }

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  validateForm = () => {
    const { user_cpassword, user_cpasswordconfirm, bValidPassword } = this.state;
    if (user_cpassword !== user_cpasswordconfirm) {
      this.setState({
        bHasAlert: true,
        aMsgErrors: [intl.get('User.mensagem_senha_diferente')]
      });
      return false;
    }
    if (!bValidPassword) {
      this.setState({
        bHasAlert: true,
        aMsgErrors: [intl.get('Customers.password_strength_error')]
      });
      return false;
    }
    return true;
  };

  handleSubmit = evt => {
    evt.preventDefault();
    const { cToken, user_cpassword, user_cpasswordconfirm } = this.state;
    if (!this.validateForm()) {
      return false;
    }
    const oLocalStorage = new EncodedLocalStorage();

    axios
      .post(`/register/activate/${cToken}`, {
        user_cpassword,
        user_cpassword_confirmation: user_cpasswordconfirm
      })
      .then(oResponse => {
        this.setState({
          bHasAlert: true,
          aMsgErrors: [intl.get('RegisterActivation.conta_ativada')],
          cAlertType: 'success'
        });
        signIn(oResponse.data, true, false);
        window.location.href = `/user/update/${oLocalStorage.get('nUserId')}`;
      })
      .catch(oError => {
        const aErrorMessages = [];

        if (oError.response.status === 422) {
          if (typeof oError.response.data.errors === 'string') {
            aErrorMessages.push(oError.response.data.errors);
          } else {
            Object.keys(oError.response.data.errors).forEach(cField => {
              if (typeof oError.response.data.errors[cField] === 'string') {
                aErrorMessages.push(oError.response.data.errors[cField]);
              } else {
                oError.response.data.errors[cField].map(oErr => aErrorMessages.push(oErr));
              }
            });
          }
        } else {
          aErrorMessages.push(intl.get('erro_500'));
        }
        this.setState({
          bHasAlert: true,
          aMsgErrors: aErrorMessages,
          cAlertType: 'error',
          bIsSending: false
        });
      });
  };

  validatePassword = evtPassword => {
    if (
      validator.isStrongPassword(evtPassword.target.value, {
        minLength: 6,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1
      })
    ) {
      this.setState({
        bValidPassword: true
      });
    } else {
      this.setState({
        bValidPassword: false
      });
    }
  };

  render() {
    const {
      aMsgErrors,
      bHasAlert,
      cAlertType,
      cToken,
      user_cpassword,
      user_cpasswordconfirm,
      bIsSending,
      bValidPassword,
      bIsSameUser
    } = this.state;
    const cPasswordClassName = bValidPassword ? 'span-strong' : 'span-weak';
    const cPasswordIntlName = bValidPassword ? 'Customers.password_strong' : 'Customers.password_weak';

    return (
      <section id="register">
        <div className="wrapper-login">
          <header>
            <div className="logo">
              <img src={imgfLogo} />
            </div>
            <h1>{intl.get('RegisterActivation.titulo')}</h1>
          </header>
          <main className="form register-activation">
            <form className="form-horizontal" onSubmit={this.handleSubmit}>
              {bHasAlert && (
                <div className={`alert ${cAlertType}`}>
                  {aMsgErrors.map(cMsg => (
                    <p key={Math.random()}>{cMsg}</p>
                  ))}
                </div>
              )}
              {cToken && !bIsSameUser && (
                <div>
                  <InputPassword
                    id="user_cpassword"
                    name="user_cpassword"
                    value={user_cpassword}
                    onChange={this.handleChange}
                    onKeyUp={this.validatePassword}
                  />
                  <div className="input-feedback input-feedback-passwordStrength">
                    {intl.get('Customers.password_strength')}:{' '}
                    <span className={cPasswordClassName}>{intl.get(cPasswordIntlName)}</span>
                  </div>
                  <div className="input-feedback input-feedback-passwordInstructions">
                    {intl.get('Customers.strong_password')}
                  </div>

                  <InputPassword
                    placeholder={intl.get('cconfirmasenha')}
                    id="user_cpasswordconfirm"
                    name="user_cpasswordconfirm"
                    value={user_cpasswordconfirm}
                    onChange={this.handleChange}
                  />

                  <Button disabled={bIsSending} type="submit">
                    {intl.get('ativar')}
                  </Button>
                </div>
              )}
              <a href="/login" className="btn alternate" style={{ float: 'left', textAlign: 'center' }}>
                {intl.get('voltar')}
              </a>
            </form>
          </main>
        </div>
      </section>
    );
  }
}

export default RegisterActivation;
