import React, { Component } from 'react';
// import { Button } from 'reactstrap';
import _ from 'lodash';
import imgfLogo from '../../assets/img/logo-acreditese.png';

class Site extends Component {
  constructor(oProps) {
    document.body.classList.add('w-bkg', 'site');
    super(oProps);
  }

  render() {
    return (
      <>
        <header style={{ background: '#53b7e8' }} id="header">
          <span id="logo">
            <img src={imgfLogo} className="App-logo" />
          </span>
        </header>
      </>
    );
  }
}

export default Site;
